<template>
  <div class="header">
     <!-- <img class="logo" alt="Lander logo" src="../assets/lander_logo.png"> -->
    <h1 class="title">Calculate your Tuition</h1>
  </div>

</template>

<script>
export default {
  name: 'Header'
}
</script>

<style scoped>
.title{
  font-family: Arial;
  text-align: center;
}

</style>