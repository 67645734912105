<template>
  <div class="hello">
    <Header />
    <Tabswrapper class="tab_wrapper">
      <Tab title="Undergrad">
        <Undergrad/>
      </Tab>
      <Tab title="Graduate">
        <Graduate/>
      </Tab>
      <Tab title="Online">
        <Online/>
      </Tab>
      <Tab title="Military">
        <Military/>
      </Tab>
      <Tab title="X">
      </Tab>
    </Tabswrapper>
  </div>
</template>

<script>
import Header from './Header.vue'
import Tabswrapper from "./Tabswrapper.vue";
import Tab from "./Tab.vue";
import Undergrad from "./Undergrad";
import Graduate from "./Graduate";
import Online from "./Online";
import Military from "./Military";

export default {
  name: "Dashboard",
  components:{
    Online,
    Tab,
    Tabswrapper,
    Header,
    Undergrad,
    Graduate,
    Military
  },
  data() {
    return {
      showDefault: false,
    }
  },
}
</script>

<style scoped>
.hello{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  border-style: solid;
  border-radius: 5px;
  border-color: lightgray;
}

.tab_wrapper{

  width: auto;
}


</style>