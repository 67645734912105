<template>
  <div class="tabs">
    <ul class="tabs__header">
      <li v-for="title in tabTitles"
          :key="title"
          :class="{selected: title == selectedTitle}"
      @click="testClick(title)">
        {{ title }}
      </li>
    </ul>
    <slot/>
  </div>
</template>

<script>
import {ref, provide} from "vue";
export default {
  name: "Tabswrapper",
  setup(props, {slots}){
    const tabTitles = ref(slots.default().map((tab) => tab.props.title))
    const selectedTitle = ref(tabTitles.value[-1])


    provide("selectedTitle", selectedTitle)
    return {
      selectedTitle,
      tabTitles,
    }
  }, methods:{
    testClick(title){
      this.selectedTitle = title
      console.log(this.selectedTitle + ' was clicked' )
    }

  },
}
</script>

<style scoped>
.tabs{
  width: auto;
}

.tabs__header{
  margin-bottom: 10px;
  list-style: none;
  padding: 0;
  display: flex;
  width: 100%;
}

.tabs__header li {
  width: auto;
  text-align: center;
  padding: 10px 24px;
  margin-right: 5px;
  background-color: lightgray;
  cursor: pointer;
  transition: 0.4s all ease-out;
}

.tabs__header li.selected {
  background-color: #004976;
  color: white;
}

</style>