<template>
  <div class="tab-content" v-show="title == selectedTitle">
    <slot/>
  </div>
</template>

<script>
import {inject} from "vue";

export default {
  name: "Tab",
  props:['title'],
    setup(){
      const selectedTitle = inject("selectedTitle")

      return {
        selectedTitle
      }
    }
}
</script>

<style scoped>
.tab-content{
  width: auto;
  height: fit-content;
  border-radius: 1px;
  border-color: lightgray;

}

</style>