<template>
    <div class="undergrad_tab">
      <div class="location">
       <h3>Are you a South Carolina Resident?</h3>
        <br>
        <input type="radio" id="resyes" v-bind:value="true" v-model="isResident">
        <label for="resyes">Yes</label>
        <br>
        <input type="radio" id="resno" v-bind:value="false" v-model="isResident">
        <label for="resno">No</label>
        <br>
      </div>
      <div class="campus">
        <h3>What campus will you be attending?</h3>
        <br>
        <select v-model="selectedCampus">
          <option disabled value="">Please select the campus you will attend</option>
          <option>Main Campus</option>
          <option>University Center of Greenville</option>
        </select>
        <br>
      </div>
      <div class="responder">
        <h3>Are you an employee of a public or non-profit organization?</h3>
        <br>
        <input type="radio" id="pubyes" v-bind:value="true" v-model="isPub">
        <label for="pubyes">Yes</label>
        <br>
        <input type="radio" id="pubno" v-bind:value="false" v-model="isPub">
        <label for="pubno">No</label>
        <br>
      </div>
      <div class="fulltime" v-show="!isPub">
        <h3>Will you be attending full time?</h3>
        <br>
        <input type="radio" id="fullyes" v-bind:value="true" v-model="isFullTime">
        <label for="fullyes">Yes</label>
        <br>
        <input type="radio" id="fullno" v-bind:value="false" v-model="isFullTime">
        <label for="fullno">No</label>
        <br>
      </div>
      <div class="hours" v-show="!isFullTime || isPub">
        <h3>How many credit hours?</h3>
        <br>
        <select v-model="creditHours">
          <option disabled value="">Please select credit hours</option>
          <option>1</option>
          <option>2</option>
          <option>3</option>
          <option>4</option>
          <option>5</option>
          <option>6</option>
          <option>7</option>
          <option>8</option>
          <option>9</option>
          <option>10</option>
          <option>11</option>
        </select>
        <br>
      </div>
      <div class="boarding" v-show="isFullTime && selectedCampus == 'Main Campus'">
        <h3>Are you planning on living on campus?</h3>
        <br>
        <input type="radio" id="boardyes" v-bind:value="true" v-model="willBoard" @change="onDormChange">
        <label for="boardyes">Yes</label>
        <br>
        <input type="radio" id="boardno" v-bind:value="false" v-model="willBoard" @change="onDormChange">
        <label for="boardno">No</label>
        <br>
        <div class="dorm" v-show="willBoard">
          <h4>Select residence hall</h4>
          <select v-model="selectedDorm"  @change="onDormSelect">
            <option v-for="item in dorms" :value="item" v-bind:key="item.residence" >
              {{ item.residence }}   ${{ item.rate}}
            </option>
          </select>
        </div>
      </div>
      <div class="meals" v-show="selectedCampus == 'Main Campus'">
        <div id="mealquestion">
        <h3>Will you be purchasing a meal plan?</h3>
        <br>
        <input type="radio" id="mealyes" v-bind:value="true" v-model="willMealPlan" @change="onMealChange">
        <label for="mealyes">Yes</label>
        <br>
        <input type="radio" id="mealno" v-bind:value="false" v-model="willMealPlan" @change="onMealChange">
        <label for="mealno">No</label>
        <br>
        </div>
        <div class="meal" v-show="willMealPlan">
          <h4>Select meal plan</h4>
          <select v-model="selectedMeal">
            <option v-for="item in visibleMeals" :value="item" v-bind:key="item.meal" >
              {{ item.meal }}- Rate:${{item.rate}}, Bearcat Bucks:${{ item.bucks}}
            </option>
          </select>
        </div>
      </div>
      <div class="calc">
        <div class="calc_button_div">
          <button class="calc_button" @click="calculateUGTuition">Calculate Tuition</button>
        </div>
        <div class="tuition_amt" v-show="showTuition">
          <div class="tuition_num">${{calculatedTuition}}</div>: per semester <div class="clearfix"></div>
          <button class="reset_button" @click="resetCalc" v-show="showTuition">Reset</button>
        </div>

      </div>

    </div>
</template>

<script>

export default {
  name: "Undergrad",
  data(){
    return{
      isResident:false,
      selectedCampus:"none",
      isFullTime:true,
      isPub:false,
      creditHours:'0',
      willBoard:false,
      dorms:[],
      selectedDorm:'',
      willMealPlan:false,
      mealplans:[],
      dormMeals:[],
      bvmcgheeMeals:[],
      selectedMeal:'',
      ugtuition:[],
      calculatedTuition:0,
      showTuition:false,
      visibleMeals:[]
    }
  }, computed:{
    checkFullTime(){
      return this.isFullTime
    }
  }, methods:{
    getDorms() {
        this.dorms = [{
          "residence": "Bearcat Village Double",
          "rate": 3100,
          "notes": ["8", "9"]
        },
          {
          "residence": "Bearcat Village Single",
          "rate": 3400,
          "notes": ["8", "9"]
        },
          {
            "residence": "Brookside 1",
            "rate": 2900,
            "notes": ["8"]
          },
          {
            "residence": "Brookside 2-7",
            "rate": 2500,
            "notes": ["8"]
          },
          {
            "residence": "Centennial Hall",
            "rate": 3700,
            "notes": ["8"]
          },
          {
            "residence": "Chipley",
            "rate": 2900,
            "notes": ["8", "9"]
          },
          {
            "residence": "Lide",
            "rate": 3100,
            "notes": ["8", "9"]
          },
          {
            "residence": "McGhee",
            "rate": 2700,
            "notes": ["8"]
          },
          {
            "residence": "New Residence Hall",
            "rate": 3600,
            "notes": ["8"]
          },
          {
            "residence": "Thomason",
            "rate": 3550,
            "notes": ["8"]
          },
          {
            "residence": "University Place",
            "rate": 3100,
            "notes": ["8"]
          },
          {
            "residence": "Williamston",
            "rate": 2900,
            "notes": ["8"]
          }];
    },
    getMeals() {
        this.mealplans = [{
          "meal": "All Access Meal Plan",
          "rate": 2000,
          "bucks": 200,
          "total": 2200,
          "notes": ["10"]
        },
          {
            "meal": "200 Block Meal Plan",
            "rate": 1900,
            "bucks": 300,
            "total": 2200,
            "notes": ["10"]
          },
          {
            "meal": "150 Block Meal Plan",
            "rate": 1650,
            "bucks": 550,
            "total": 2200,
            "notes": ["10"]
          },
          {
            "meal": "100 Block Meal Plan",
            "rate": 1110,
            "bucks": 350,
            "total": 1460,
            "notes": ["10"]
          },
          {
            "meal": "75 Block Meal Plan",
            "rate": 830,
            "bucks": 300,
            "total": 1130,
            "notes": ["10"]
          },
          {
            "meal": "50 Block Meal Plan",
            "rate": 620,
            "bucks": 275,
            "total": 895,
            "notes": ["10"]
          },
          {
            "meal": "350 Bearcat Bucks",
            "rate": 0,
            "bucks": 350,
            "total": 350,
            "notes": ["10"]
          }];
        this.dormMeals = [{
          "meal": "All Access Meal Plan",
          "rate": 2000,
          "bucks": 200,
          "total": 2200,
          "notes": ["10"]
        },
          {
            "meal": "200 Block Meal Plan",
            "rate": 1900,
            "bucks": 300,
            "total": 2200,
            "notes": ["10"]
          },
          {
            "meal": "150 Block Meal Plan",
            "rate": 1650,
            "bucks": 550,
            "total": 2200,
            "notes": ["10"]
          }];
        this.bvmcgheeMeals=[{
          "meal": "All Access Meal Plan",
          "rate": 2000,
          "bucks": 200,
          "total": 2200,
          "notes": ["10"]
        },
          {
            "meal": "200 Block Meal Plan",
            "rate": 1900,
            "bucks": 300,
            "total": 2200,
            "notes": ["10"]
          },
          {
            "meal": "150 Block Meal Plan",
            "rate": 1650,
            "bucks": 550,
            "total": 2200,
            "notes": ["10"]
          },
          {
            "meal": "100 Block Meal Plan",
            "rate": 1110,
            "bucks": 350,
            "total": 1460,
            "notes": ["10"]
          },
          {
            "meal": "75 Block Meal Plan",
            "rate": 830,
            "bucks": 300,
            "total": 1130,
            "notes": ["10"]
          },
          {
            "meal": "50 Block Meal Plan",
            "rate": 620,
            "bucks": 275,
            "total": 895,
            "notes": ["10"]
          },
          {
            "meal": "350 Bearcat Bucks",
            "rate": 0,
            "bucks": 350,
            "total": 350,
            "notes": ["10"]
          }];
    },
    getUGtuition() {
        this.ugtuition = [{
          "stutype": "In-State",
          "location": "Main",
          "fulltime": 5350,
          "perhour": 446,
          "genfees": 500,
          "notes": ["1", "2"]
        },
          {
            "stutype": "Out of State",
            "location": "Main",
            "fulltime": 10150,
            "perhour": 846,
            "genfees": 500,
            "notes": ["1", "2"]
          },
          {
            "stutype": "In-State",
            "location": "University Center",
            "fulltime": 0,
            "perhour": 330,
            "genfees": 42,
            "notes": ["4"]
          },
          {
            "stutype": "Out of State",
            "location": "University Center",
            "fulltime": 0,
            "perhour": 330,
            "genfees": 42,
            "notes": ["4"]
          },
          {
            "stutype": "Public",
            "location": "Main",
            "fulltime": 0,
            "perhour": 290,
            "genfees": 42,
            "notes": ["4"]
          }]
    },
    calculateUGTuition(){

      if(this.calculatedTuition > 0){
        alert('Please reset Calculator and complete questions to get new results')
      } else {
        if(this.selectedMeal == '' && this.willBoard){
          alert('Meal Plan must be selected to continue.')
        }else{
          let tuition_total = 0
          let res =''
          if (this.isResident){
            res = "In-State"
          } else {
            res = "Out of State"
          }

          if (this.isPub){
            res = "Public"
            this.isFullTime = false
            this.selectedCampus = 'Main Campus'
          }

          let campus =''
          if (this.selectedCampus == 'Main Campus'){
            campus = "Main"
          } else {
            campus = "University Center"
          }
          let tuition_rate =0

          if (!this.willBoard && this.isFullTime && !this.willMealPlan){
            this.selectedMeal = {
              "meal": "No Meal Plan-NR125",
              "rate": 0,
              "bucks": 125,
              "total": 125,
              "notes": ["10", "11"]
            }
          }


          if(this.isFullTime && this.selectedCampus === 'Main Campus'){
            tuition_rate = this.ugtuition.filter(function (record){
              return record.stutype == res && record.location == campus
            })[0].fulltime + this.ugtuition.filter(function (record){
              return record.stutype == res && record.location == campus
            })[0].genfees;
          } else {
            tuition_rate = this.ugtuition.filter(function (record){
              return record.stutype == res && record.location == campus
            })[0].perhour * parseInt(this.creditHours)
            let fees = 42;
            if(fees * parseInt(this.creditHours) <= 500){
              fees = fees * parseInt(this.creditHours)
            } else {
              fees = 500
            }
            tuition_rate+=fees
          }
          tuition_total+=tuition_rate
          if(this.willMealPlan && this.selectedMeal.total != ''){
            tuition_total+= parseInt(this.selectedMeal.total)
          }
          if(this.willBoard && this.selectedDorm.rate != ''){
            tuition_total+= parseInt(this.selectedDorm.rate)
          }
          this.calculatedTuition = tuition_total
          if(this.calculatedTuition > 0){
            this.showTuition =true
          }
        }
      }
      // alert(this.calculatedTuition)
    },
    resetCalc(){
      console.log('Calculation reset')
      this.showTuition = false
      this.isFullTime = true
      this.isResident = false
      this.isPub = false
      this.selectedCampus = ''
      this.selectedDorm = ''
      this.selectedMeal = ''
      this.creditHours = 0
      this.willMealPlan = false
      this.willBoard = false
      this.calculatedTuition = 0
    },
    onDormChange: function (){
      if(this.willBoard){
        document.getElementById("mealno").checked = false;
        document.getElementById("mealno").disabled = true;
        document.getElementById("mealyes").checked = true;
        document.getElementById("mealquestion").style.display = "none"
        this.willMealPlan = true;

      }
      else {
        document.getElementById("mealno").disabled = false;
        document.getElementById("mealquestion").style.display = "block";
        this.visibleMeals = this.mealplans;
      }
    },
    onDormSelect: function (){
      if(this.selectedDorm.residence == 'Bearcat Village Single' || this.selectedDorm.residence == 'Bearcat Village Double' ||this.selectedDorm.residence == 'McGhee' ){
        this.visibleMeals = this.bvmcgheeMeals;
      }else{
        this.visibleMeals = this.dormMeals;
      }
    },
    onMealChange: function(){
      if(this.willMealPlan && !this.willBoard){
        this.visibleMeals = this.mealplans;
      } else {
        if(this.selectedDorm.residence == 'Bearcat Village Single' || this.selectedDorm.residence == 'Bearcat Village Double' ||this.selectedDorm.residence == 'McGhee' ){
          this.visibleMeals = this.bvmcgheeMeals;
        }else{
          this.visibleMeals = this.dormMeals;
        }
      }


    }

  },mounted() {
    this.getDorms()
    this.getMeals()
    this.getUGtuition()
  }
}
</script>

<style scoped>
.undergrad_tab{
  text-align: left;
}

.location, .campus, .fulltime, .hours, .boarding, .meals, .responder{
  width: auto;
  border-style: solid;
  padding-left: 10px;
  border-bottom: solid;
  border-bottom-color: lightgray;
  border-right: none;
  border-left: none;
  border-top: none;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.calc{
 text-align: right;
  overflow: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.calc_button{
  float:left;
  margin-left: 20px;
  display: block;
  background-color: #004976;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.tuition_amt{
  float: right;
  padding-right: 20px;
  display: block;
}

.tuition_num{
  float: left;
  font-family: "Droid Serif";
  font-weight: bold;
  font-size: xx-large;
}

.reset_button{
  float: right;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  background-color: #004976;
  color: white;
  margin-right: 20px;
  margin-top: 20px;
}



</style>