<template>
  <div class="mil_tab">
    <div class="mil_level">
      <h3>What course level will you be enrolling in?</h3>
      <br>
      <input type="radio" id="milgradyes" v-bind:value="true" v-model="isGrad">
      <label for="milgradyes">Graduate</label>
      <br>
      <input type="radio" id="milgradno" v-bind:value="false" v-model="isGrad">
      <label for="milgradno">Undergraduate</label>
      <br>
    </div>
    <div class="milActiveDuty">
      <h3>Are you an active duty or National Guard service member?</h3>
      <br>
      <input type="radio" id="milyes" v-bind:value="true" v-model="isActiveDutyMil">
      <label for="milyes">Yes</label>
      <br>
      <input type="radio" id="milno" v-bind:value="false" v-model="isActiveDutyMil">
      <label for="milno">No</label>
      <br>
    </div>
    <div class="milVeteran" v-show="isGrad && isGrad !== null">
      <h3>Are you a veteran or veteran's spouse?</h3>
      <br>
      <input type="radio" id="milvetyes" v-bind:value="true" v-model="isMilVeteran">
      <label for="milvetyes">Yes</label>
      <br>
      <input type="radio" id="milvetsno" v-bind:value="false" v-model="isMilVeteran">
      <label for="milvetsno">No</label>
      <br>
    </div>
    <div class="milFamily" v-show="!isGrad && isGrad !== null">
      <h3>Are you a family member of a service member?</h3>
      <br>
      <input type="radio" id="famyes" v-bind:value="true" v-model="isMilFamily">
      <label for="famyes">Yes</label>
      <br>
      <input type="radio" id="famno" v-bind:value="false" v-model="isMilFamily">
      <label for="famno">No</label>
      <br>
    </div>

    <div class="milhours">
      <h3>How many credit hours?</h3>
      <br>
      <select v-model="milHours" @change="onHoursChange">
        <option disabled value="">Please select estimated credit hours</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option>
        <option>11</option>
        <option>12</option>
        <option>13</option>
        <option>14</option>
        <option>15</option>
      </select>
      <br>
    </div>

    <div class="milBoard" v-show="milHours > 11">
      <h3>Will you be staying on campus?</h3>
      <br>
      <input type="radio" id="milboardyes" v-bind:value="true" v-model="willBoard" @change="onBoardChange">
      <label for="milboardyes">Yes</label>
      <br>
      <input type="radio" id="milboardno" v-bind:value="false" v-model="willBoard" @change="onBoardChange">
      <label for="milboardno">No</label>
      <br>
      <div class="dorm" v-show="willBoard">
        <h4>Select residence hall</h4>
        <select v-model="selectedDorm"  @change="onDormSelect">
          <option v-for="item in dorms" :value="item" v-bind:key="item.residence" >
            {{ item.residence }}   ${{ item.rate}}
          </option>
        </select>
      </div>
    </div>

    <div class="meals" v-show="milHours > 11">
      <div id="milmealquestion">
        <h3>Will you be purchasing a meal plan?</h3>
        <br>
        <input type="radio" id="milmealyes" v-bind:value="true" v-model="willMealPlan" @change="onMealChange">
        <label for="milmealyes">Yes</label>
        <br>
        <input type="radio" id="milmealno" v-bind:value="false" v-model="willMealPlan" @change="onMealChange">
        <label for="milmealno">No</label>
        <br>
      </div>
      <div class="meal" v-show="willMealPlan">
        <h4>Select meal plan</h4>
        <select v-model="selectedMeal">
          <option v-for="item in visibleMeals" :value="item" v-bind:key="item.meal" >
            {{ item.meal }}- Rate:${{item.rate}}, Bearcat Bucks:${{ item.bucks}}
          </option>
        </select>
      </div>
    </div>

    <div class="mil_calc">
      <div class="mil_calc_button_div">
        <button class="mil_calc_button" @click="calculateMilTuition">Calculate Tuition</button>
      </div>
      <div class="mil_tuition_amt" v-show="showMilTuition">
        <div class="mil_calc_tuition_num">${{calculatedMilTuition}}</div>: per semester
        <div class="clearfix"></div>
        <button class="reset_button" @click="resetCalc" v-show="showMilTuition">Reset</button>
      </div>

    </div>


  </div>
</template>

<script>

export default {
  name: "Military",
  data(){
    return{
      isGrad:null,
      isActiveDutyMil:false,
      isMilVeteran:false,
      isMilFamily:false,
      willBoard:false,
      willMealPlan:false,
      milHours:0,
      selectedDorm:'',
      selectedMeal:'',
      calculatedMilTuition:0,
      showMilTuition:false,
      miltuition:[],
      dorms:[],
      mealplans:[],
      dormMeals:[],
      bvmcgheeMeals:[],
      visibleMeals:[]
    }
  },mounted() {
    this.getMilTuition();
    this.getDorms();
    this.getMeals()
  }, methods:{
    getMilTuition() {

      this.miltuition = [{
        "stutype": "Active Duty or National Guard",
        "level": "Grad",
        "fulltime": 0,
        "perhour": 250,
        "genfees": 42,
        "notes": ["4"]
      },
        {
          "stutype": "Veteran or Spouse",
          "level": "Grad",
          "fulltime": 0,
          "perhour": 380,
          "genfees": 42,
          "notes": ["4"]
        },{
          "stutype": "Military Family",
          "level": "Undergrad",
          "fulltime": 4350,
          "perhour": 362,
          "genfees": 500,
          "notes": ["1", "3"]
        },
        {
          "stutype": "Active Duty or National Guard",
          "level": "Undergrad",
          "fulltime": 0,
          "perhour": 250,
          "genfees": 42,
          "notes": ["1", "3"]
        }];

    },
    calculateMilTuition(){
      let mil_per_hour = 0
      let mil_fee  = 0
      let level = ''
      let stutype = ''

      if (this.isGrad){
        level = 'Grad'
        if(this.isActiveDutyMil){
          stutype = 'Active Duty or National Guard'
        } else if (this.isMilVeteran){
          stutype = 'Veteran or Spouse'
        } else {
          alert('Please use Graduate tab to calculate tuition')
          return;
        }
      }  else {
        level = 'Undergrad'
        if(this.isActiveDutyMil){
          stutype = 'Active Duty or National Guard'
        } else if (this.isMilFamily){
          stutype = 'Military Family'
        } else {
          alert('Please use Undergrad tab to calculate tuition')
          return;
        }
      }

      mil_per_hour = this.miltuition.find(function (record){
        return record.stutype === stutype && record.level === level
      }).perhour
      mil_fee = this.miltuition.find(function (record){
        return record.stutype === stutype && record.level === level
      }).genfees

      mil_per_hour*=parseInt(this.milHours)
      mil_fee*=parseInt(this.milHours)

      if (!this.isGrad && this.isMilFamily && (parseInt(this.milHours) >= 12)){
        mil_fee = 500
        mil_per_hour = this.miltuition.find(function (record){
          return record.stutype === stutype && record.level === level
        }).fulltime
      }

      if ((55 * parseInt(this.milHours) >= 500)){
        mil_fee = 500
      } else {
        mil_fee = 55 * parseInt(this.milHours)
      }

      if (!this.willBoard && this.milHours > 11 && !this.willMealPlan){
        this.selectedMeal = {
          "meal": "No Meal Plan-NR125",
          "rate": 0,
          "bucks": 125,
          "total": 125,
          "notes": ["10", "11"]
        }
      }

      this.calculatedMilTuition = mil_per_hour + mil_fee
      if(this.selectedDorm != ''){
        this.calculatedMilTuition+=parseInt(this.selectedDorm.rate)
      }
      if(this.selectedMeal != ''){
        this.calculatedMilTuition+=parseInt(this.selectedMeal.total)
      }

      this.showMilTuition = true
    },
    resetCalc(){
      console.log('Calculation reset')
      this.showMilTuition = false
      this.isGrad = null
      this.isActiveDutyMil = false
      this.isMilFamily = false
      this.isMilVeteran = false
      this.milHours = 0
    },
    onHoursChange(){
      if(this.milHours < 12){
        this.willBoard = false;
        this.willMealPlan = false;
        this.selectedMeal ='';
        this.selectedDorm ='';
      }
    },
    onBoardChange: function (){
      if(this.willBoard){
        document.getElementById("milmealno").checked = false;
        document.getElementById("milmealno").disabled = true;
        document.getElementById("milmealyes").checked = true;
        document.getElementById("milmealquestion").style.display = "none"
        this.willMealPlan = true;

      }
      else {
        document.getElementById("milmealno").disabled = false;
        document.getElementById("milmealquestion").style.display = "block";
        this.visibleMeals = this.mealplans;
        this.selectedDorm ='';
      }
    },
    onDormSelect: function (){
      if(this.selectedDorm.residence == 'Bearcat Village Single' || this.selectedDorm.residence == 'Bearcat Village Double' ||this.selectedDorm.residence == 'McGhee' ){
        this.visibleMeals = this.bvmcgheeMeals;
      }else{
        this.visibleMeals = this.dormMeals;
      }
    },
    onMealChange: function(){
      if(this.willMealPlan && !this.willBoard){
        this.visibleMeals = this.mealplans;
      } else {
        if(this.selectedDorm.residence == 'Bearcat Village Single' || this.selectedDorm.residence == 'Bearcat Village Double' ||this.selectedDorm.residence == 'McGhee' ){
          this.visibleMeals = this.bvmcgheeMeals;
        }else{
          this.visibleMeals = this.dormMeals;
        }
        this.selectedMeal =''
      }
    },
    getDorms() {
      this.dorms = [{
        "residence": "Bearcat Village Double",
        "rate": 3100,
        "notes": ["8", "9"]
      },
        {
          "residence": "Bearcat Village Single",
          "rate": 3400,
          "notes": ["8", "9"]
        },
        {
          "residence": "Brookside 1",
          "rate": 2900,
          "notes": ["8"]
        },
        {
          "residence": "Brookside 2-7",
          "rate": 2500,
          "notes": ["8"]
        },
        {
          "residence": "Centennial Hall",
          "rate": 3700,
          "notes": ["8"]
        },
        {
          "residence": "Chipley",
          "rate": 2900,
          "notes": ["8", "9"]
        },
        {
          "residence": "Lide",
          "rate": 3100,
          "notes": ["8", "9"]
        },
        {
          "residence": "McGhee",
          "rate": 2700,
          "notes": ["8"]
        },
        {
          "residence": "New Residence Hall",
          "rate": 3600,
          "notes": ["8"]
        },
        {
          "residence": "Thomason",
          "rate": 3550,
          "notes": ["8"]
        },
        {
          "residence": "University Place",
          "rate": 3100,
          "notes": ["8"]
        },
        {
          "residence": "Williamston",
          "rate": 2900,
          "notes": ["8"]
        }];
    },
    getMeals() {
      this.mealplans = [{
        "meal": "All Access Meal Plan",
        "rate": 2000,
        "bucks": 200,
        "total": 2200,
        "notes": ["10"]
      },
        {
          "meal": "200 Block Meal Plan",
          "rate": 1900,
          "bucks": 300,
          "total": 2200,
          "notes": ["10"]
        },
        {
          "meal": "150 Block Meal Plan",
          "rate": 1650,
          "bucks": 550,
          "total": 2200,
          "notes": ["10"]
        },
        {
          "meal": "100 Block Meal Plan",
          "rate": 1110,
          "bucks": 350,
          "total": 1460,
          "notes": ["10"]
        },
        {
          "meal": "75 Block Meal Plan",
          "rate": 830,
          "bucks": 300,
          "total": 1130,
          "notes": ["10"]
        },
        {
          "meal": "50 Block Meal Plan",
          "rate": 620,
          "bucks": 275,
          "total": 895,
          "notes": ["10"]
        },
        {
          "meal": "350 Bearcat Bucks",
          "rate": 0,
          "bucks": 350,
          "total": 350,
          "notes": ["10"]
        }];
      this.dormMeals = [{
        "meal": "All Access Meal Plan",
        "rate": 2000,
        "bucks": 200,
        "total": 2200,
        "notes": ["10"]
      },
        {
          "meal": "200 Block Meal Plan",
          "rate": 1900,
          "bucks": 300,
          "total": 2200,
          "notes": ["10"]
        },
        {
          "meal": "150 Block Meal Plan",
          "rate": 1650,
          "bucks": 550,
          "total": 2200,
          "notes": ["10"]
        }];
      this.bvmcgheeMeals=[{
        "meal": "All Access Meal Plan",
        "rate": 2000,
        "bucks": 200,
        "total": 2200,
        "notes": ["10"]
      },
        {
          "meal": "200 Block Meal Plan",
          "rate": 1900,
          "bucks": 300,
          "total": 2200,
          "notes": ["10"]
        },
        {
          "meal": "150 Block Meal Plan",
          "rate": 1650,
          "bucks": 550,
          "total": 2200,
          "notes": ["10"]
        },
        {
          "meal": "100 Block Meal Plan",
          "rate": 1110,
          "bucks": 350,
          "total": 1460,
          "notes": ["10"]
        },
        {
          "meal": "75 Block Meal Plan",
          "rate": 830,
          "bucks": 300,
          "total": 1130,
          "notes": ["10"]
        },
        {
          "meal": "50 Block Meal Plan",
          "rate": 620,
          "bucks": 275,
          "total": 895,
          "notes": ["10"]
        },
        {
          "meal": "350 Bearcat Bucks",
          "rate": 0,
          "bucks": 350,
          "total": 350,
          "notes": ["10"]
        }];
    }
  }
}
</script>

<style scoped>
.mil_tab{
  text-align: left;
}

.mil_level, .milActiveDuty, .milVeteran, .milFamily, .milhours, .milBoard, .meals{
  width: auto;
  border-style: solid;
  padding-left: 10px;
  border-bottom: solid;
  border-bottom-color: lightgray;
  border-right: none;
  border-left: none;
  border-top: none;
  padding-bottom: 10px;
  margin-bottom: 5px;
}
.mil_calc{
  text-align: right;
  overflow: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.mil_calc_button{
  float:left;
  margin-left: 20px;
  display: block;
  background-color: #004976;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.mil_tuition_amt{
  float: right;
  padding-right: 20px;
  display: inline-block;

}

.mil_calc_tuition_num{
  float: left;
  font-family: "Droid Serif";
  font-weight: bold;
  font-size: xx-large;
}

.reset_button{
  float: right;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  background-color: #004976;
  color: white;
  margin-right: 20px;
  margin-top: 20px;
}


</style>