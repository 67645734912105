<template>
  <div class="grad_tab">
    <div class="gradlocation" v-show="showRes">
      <h3>Are you a South Carolina Resident?</h3>
      <br>
      <input type="radio" id="gresyes" v-bind:value="true" v-model="isResidentGrad">
      <label for="gresyes">Yes</label>
      <br>
      <input type="radio" id="gresno" v-bind:value="false" v-model="isResidentGrad">
      <label for="gresno">No</label>
      <br>
    </div>
    <div class="gradActiveDuty">
      <h3>Are you an active duty or National Guard service member?</h3>
      <br>
      <input type="radio" id="dutyyes" v-bind:value="true" v-model="isActiveDuty">
      <label for="dutyyes">Yes</label>
      <br>
      <input type="radio" id="dutysno" v-bind:value="false" v-model="isActiveDuty">
      <label for="dutysno">No</label>
      <br>
    </div>
    <div class="gradVeteran">
      <h3>Are you a veteran or veteran's spouse?</h3>
      <br>
      <input type="radio" id="vetyes" v-bind:value="true" v-model="isVeteran">
      <label for="vetyes">Yes</label>
      <br>
      <input type="radio" id="vetsno" v-bind:value="false" v-model="isVeteran">
      <label for="vetsno">No</label>
      <br>
    </div>

    <div class="gradpublic">
      <h3>Are you an employee of a public or non-profit organization?</h3>
      <br>
      <input type="radio" id="pubyes" v-bind:value="true" v-model="isPub">
      <label for="pubyes">Yes</label>
      <br>
      <input type="radio" id="pubno" v-bind:value="false" v-model="isPub">
      <label for="pubno">No</label>
      <br>
    </div>
    <div class="gradhours">
      <h3>How many credit hours?</h3>
      <br>
      <select v-model="gradHours">
        <option disabled value="">Please select estimated credit hours</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option>
        <option>11</option>
        <option>12</option>
        <option>13</option>
        <option>14</option>
        <option>15</option>
      </select>
      <br>
    </div>
    <div class="grad_calc">
      <div class="grad_calc_button_div"><button class="grad_calc_button" @click="calculateGradTuition">Calculate Tuition</button></div>
      <div class="grad_tuition_amt" v-show="showGradTuition"><div class="grad_tuition_num">${{calculatedGradTuition}}</div>: per semester
        <div class="clearfix"></div>
        <button class="reset_button" @click="resetCalc" v-show="showGradTuition">Reset</button>
      </div>

    </div>

  </div>

</template>

<script>

export default {
  name: "Graduate",
  data(){
    return{
      isResidentGrad:true,
      isActiveDuty:false,
      isVeteran:false,
      isPub:false,
      gradHours:0,
      calculatedGradTuition:0,
      showGradTuition:false,
      gradtuition:[],
      showRes:false
    }
  },mounted() {
    this.getGradTuition()
  }, methods:{
    getGradTuition() {
        this.gradtuition = [{
          "stutype": "In-state",
          "perhour": 545,
          "genfees": 0,
          "notes": []
        },
          {
            "stutype": "Out of State",
            "perhour": 545,
            "genfees": 0,
            "notes": []
          },
          {
            "stutype": "Active Duty and National Guard",
            "perhour": 250,
            "genfees": 55,
            "notes": ["6"]
          },
          {
            "stutype": "Veteran or Spouse",
            "perhour": 380,
            "genfees": 55,
            "notes": ["6"]
          },
          {
            "stutype": "Public",
            "perhour": 360,
            "genfees": 55,
            "notes": ["6"]
          }]
      }
    ,calculateGradTuition(){
      let grad_per_hour = 0
      let grad_fee  = 0
      let student_type = ''

      if (this.isPub){
        student_type = 'Public'
      }
       else if (this.isActiveDuty){
        student_type = 'Active Duty and National Guard'
      } else if(this.isVeteran){
        student_type = 'Veteran or Spouse'
      } else if (this.isResidentGrad){
        student_type = 'In-state'
      } else {
        student_type = 'Out of State'
      }

      grad_per_hour = this.gradtuition.find(rec => rec.stutype === student_type).perhour
      grad_fee = this.gradtuition.find(rec => rec.stutype === student_type).genfees

      grad_per_hour*=parseInt(this.gradHours)
      grad_fee*=parseInt(this.gradHours)

      if (!this.isActiveDuty && !this.isVeteran && !this.isPub){
        grad_fee = 0
      }

      this.calculatedGradTuition = grad_per_hour + grad_fee
      this.showGradTuition = true
    }, resetCalc(){
      console.log('Calculation reset')
      this.showGradTuition = false
      this.isVeteran = false
      this.isActiveDuty = false
      this.isPub = false
      this.gradHours = 0
    }
  }
}
</script>

<style scoped>
.grad_tab{
  text-align: left;
}

.gradlocation, .gradActiveDuty, .gradVeteran, .gradhours, .gradpublic{
  width: auto;
  border-style: solid;
  padding-left: 10px;
  border-bottom: solid;
  border-bottom-color: lightgray;
  border-right: none;
  border-left: none;
  border-top: none;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.grad_calc{
  text-align: right;
  overflow: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.grad_calc_button{
  float:left;
  margin-left: 20px;
  display: block;
  background-color: #004976;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}

.grad_tuition_amt{
  float: right;
  padding-right: 20px;
  display: block;
}

.grad_tuition_num{
  float: left;
  font-family: "Droid Serif";
  font-weight: bold;
  font-size: xx-large;
}

.reset_button{
  float: right;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  background-color: #004976;
  color: white;
  margin-right: 20px;
  margin-top: 20px;
}

</style>