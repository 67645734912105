<template>
  <div class="ol_tab">
    <div class="online_location" v-show="showRes">
      <h3>Are you a South Carolina Resident?</h3>
      <br>
      <input type="radio" id="olyes" v-bind:value="true" v-model="isResidentOL">
      <label for="olyes">Yes</label>
      <br>
      <input type="radio" id="olno" v-bind:value="false" v-model="isResidentOL">
      <label for="olno">No</label>
      <br>
    </div>
    <div class="level">
      <h3>What course level will you be enrolled in?</h3>
      <br>
      <select v-model="selectedLevel">
        <option disabled value="">Please select your enrollment Level</option>
        <option>Undergraduate</option>
        <option>Graduate</option>
      </select>
      <br>
    </div>
    <div class="olhours">
      <h3>How many credit hours?</h3>
      <br>
      <select v-model="olHours">
        <option disabled value="">Please select estimated credit hours</option>
        <option>1</option>
        <option>2</option>
        <option>3</option>
        <option>4</option>
        <option>5</option>
        <option>6</option>
        <option>7</option>
        <option>8</option>
        <option>9</option>
        <option>10</option>
        <option>11</option>
        <option>12</option>
        <option>13</option>
        <option>14</option>
        <option>15</option>
      </select>
      <br>
    </div>
    <div class="ol_calc">
      <div class="ol_calc_button_div"><button class="ol_calc_button" @click="calculateOLTuition">Calculate Tuition</button></div>
      <div class="ol_tuition_amt" v-show="showOLTuition">
        <div class="ol_tuition_num">${{calculatedOLTuition}}</div>: per semester <div class="clearfix"></div>
        <button class="reset_button" @click="resetCalc" v-show="showOLTuition">Reset</button>
      </div>

    </div>

  </div>
</template>

<script>

export default {
  name: "Online",
  data(){
    return{
      isResidentOL:true,
      selectedLevel:'',
      olHours:0,
      calculatedOLTuition:0,
      showOLTuition:false,
      oltuition:[],
      showRes:false
    }
  },mounted() {
    this.getOLTuition()
  }, methods:{
    getOLTuition() {

        this.oltuition = [{
          "location": "In",
          "level":"Undergraduate",
          "perhour": 390,
          "genfees": 42,
          "notes": ["4"]
        },
          {
            "location": "Out",
            "level":"Undergraduate",
            "perhour": 390,
            "genfees": 42,
            "notes": ["4"]
          },
          {
            "location": "In",
            "level":"Graduate",
            "perhour": 545,
            "genfees": 0,
            "notes": []
          },
          {
            "location": "Out",
            "level":"Graduate",
            "perhour": 545,
            "genfees": 0,
            "notes": []
          }];

    },calculateOLTuition(){
      let ol_per_hour = 0
      let ol_fee  = 0
      let location = ''
      const level = this.selectedLevel

      if (this.isResidentOL){
        location = 'In'
      }  else {
        location = 'Out'
      }

      ol_per_hour = this.oltuition.find(function (record){
        return record.location === location && record.level === level
      }).perhour

      ol_per_hour*=parseInt(this.olHours)

      if((42 * parseInt(this.olHours)) <= 500){
        ol_fee = 42 * parseInt(this.olHours)
      } else {
        ol_fee = 500
      }

      if (level === 'Graduate'){
        ol_fee = 0
      }

      this.calculatedOLTuition = ol_per_hour + ol_fee
      this.showOLTuition = true
    }, resetCalc(){
      console.log('Calculation reset')
      this.showOLTuition = false
      this.selectedLevel = ''
      this.olHours = 0
    }
  }
}
</script>

<style scoped>
.ol_tab{
  text-align: left;
}

.online_location, .level, .olhours{
  width: auto;
  border-style: solid;
  padding-left: 10px;
  border-bottom: solid;
  border-bottom-color: lightgray;
  border-right: none;
  border-left: none;
  border-top: none;
  padding-bottom: 10px;
  margin-bottom: 5px;
}

.ol_calc{
  text-align: right;
  overflow: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ol_calc_button{
  float:left;
  margin-left: 20px;
  display: block;
  background-color: #004976;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  cursor: pointer;
}
.ol_tuition_amt{
  float: right;
  padding-right: 20px;
  display: block;
}

.ol_tuition_num{
  float: left;
  font-family: "Droid Serif";
  font-weight: bold;
  font-size: xx-large;
}

.reset_button{
  float: right;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
  background-color: #004976;
  color: white;
  margin-right: 20px;
  margin-top: 20px;
}

</style>